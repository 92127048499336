import { camelizeKeys, decamelizeKeys } from 'humps';
import isObject from 'lodash.isobject';
import queryString from 'query-string';

const METHOD_GET = 'GET';
const METHOD_POST = 'POST';
const METHOD_PUT = 'PUT';
const METHOD_DELETE = 'DELETE';
const METHOD_PATCH = 'PATCH';

/**
 * Opinionated fetch() wrapper for using our API that does the following:
 *
 * 1. sends same-origin cookies along for authentication
 * 2. includes the CSRF token as a header
 * 3. serializes params:
 *    - converts camelCase -> snake_case
 *    - if method is GET or DELETE, transforms params to query params
 *    - otherwise (POST or PUT), serializes params to JSON
 * 4. rejects with error for non-2XX responses
 * 5. deserializes response from JSON, and converts snake_case JSON -> camelCase
 *    JSON
 *
 * Returns a standard Response object, with an additional `parsed` field added
 * containing parsed JSON, if applicable.
 *
 * Throws an Error that either comes from fetch() (for e.g. "could not connect
 * to host"), or is constructed from a non-2XX HTTP response (e.g. "bad
 * request"). This error has a `fetchError` property set to `true` on it so it
 * can be easily distinguished from other unexpected errors:
 *
 * ```
 * let resp;
 * try {
 *   resp = await apiFetch('/api/availability', {
 *     params: {
 *       tz: this.props.selectedTimeZone,
 *     },
 *   });
 * } catch(err) {
 *   if (!err.fetchError) {
 *     // unexpected error
 *     throw err;
 *   }
 *
 *   if (err.res) {
 *     // handle HTTP error
 *     const message = err.res.error.message;
 *     // ...
 *   } else {
 *     // handle fetch() error
 *     const message = 'An unexpected error occurred';
 *     // ...
 *   }
 * }
 * ```
 */
export async function apiFetch(
  url,
  { method = METHOD_GET, headers, body, params } = {}
) {
  // 1. Append ?query to URL if necessary
  if (params) {
    url = appendQueryParams(url, params);
  }
  // 2. Decamelize and Stringify Body
  if (body) {
    body = JSON.stringify(decamelizeKeys(body));
  }
  // 3. Make Request
  let res;
  try {
    res = await fetch(url, {
      body,
      method: method.toUpperCase(),
      headers: headers,
      credentials: 'same-origin',
    });
  } catch (err) {
    err.fetchError = true;
    if (
      document.location.pathname.includes('activ') ||
      document.location.pathname.includes('confirm') ||
      document.location.pathname.includes('book')
    ) {
      throw err;
    }
    throw err;
  }

  if (!(res.status >= 200 && res.status < 300)) {
    const error = new Error(res.statusText);
    error.fetchError = true;
    error.res = res;
    res.parsed = await parseResponseJson(res);
    if (
      document.location.pathname.includes('activ') ||
      document.location.pathname.includes('confirm') ||
      document.location.pathname.includes('book')
    ) {
      throw error;
    }
  }

  if (res.status !== 204) {
    res.parsed = await parseResponseJson(res);
  }

  return res;
}

/**
 * GET API Fetch Convienience Wrapper
 * @param {String} endpoint
 * @param {Object} params
 * @returns {Promise}
 */
export async function apiGet(
  endpoint,
  params = {},
  headers = apiRequestHeaders()
) {
  return await apiFetch(endpoint, {
    method: METHOD_GET,
    headers,
    params,
  });
}

/**
 * POST API Fetch Convienience Wrapper
 * @param {String} endpoint
 * @param {Object} body
 * @param {Object} headers
 * @returns {Promise}
 */
export async function apiPost(
  endpoint,
  body = {},
  headers = apiRequestHeaders()
) {
  return await apiFetch(endpoint, {
    method: METHOD_POST,
    headers,
    body,
  });
}

/**
 * PUT API Fetch Convienience Wrapper
 * @param {String} endpoint
 * @param {Object} body
 * @param {Object} headers
 * @returns {Promise}
 */
export async function apiPut(
  endpoint,
  body = {},
  headers = apiRequestHeaders(),
  params = {}
) {
  return await apiFetch(endpoint, {
    method: METHOD_PUT,
    headers,
    body,
    params,
  });
}

/**
 * PATCH API Fetch Convienience Wrapper
 * @param {String} endpoint
 * @param {Object} body
 * @param {Object} headers
 * @returns {Promise}
 */
export async function apiPatch(
  endpoint,
  body = {},
  headers = apiRequestHeaders(),
  params = {}
) {
  return await apiFetch(endpoint, {
    method: METHOD_PATCH,
    headers,
    body,
    params,
  });
}

/**
 * DELETE API Fetch Convienience Wrapper
 * @param {String} endpoint
 * @param {Object} params
 * @returns {Promise}
 */
export async function apiDelete(
  endpoint,
  body = {},
  headers = apiRequestHeaders()
) {
  return await apiFetch(endpoint, {
    method: METHOD_DELETE,
    headers,
    body,
  });
}

/**
 * @param {String} url
 * @param {Object} params
 * @returns {String} url?params
 */
export const appendQueryParams = (url, params) =>
  queryString.stringifyUrl(
    {
      url,
      query: decamelizeKeys(
        Object.keys(params).reduce((preparedQueryParams, param) => {
          // 1. Default
          // Pass Key/Value back through preparedQueryParams
          let key = param;
          let value = params[param];
          // 2. Object Handling
          // Convert { filter: { name: "johnny" }} => { filter[name]: johnny }
          if (isObject(params[param]) && !Array.isArray(params[param])) {
            // 3a. Format & Apply Object Key / Value
            Object.keys(params[param]).forEach((objectParamKey) => {
              preparedQueryParams[`${param}[${objectParamKey}]`] =
                params[param][objectParamKey];
            });
          } else {
            // 3b. Re-Apply Key / Value
            preparedQueryParams[key] = value;
          }

          return preparedQueryParams;
        }, {})
      ),
    },
    { skipNull: true, arrayFormat: 'bracket' }
  );

/**
 * @param {Response} res
 * @returns {Object}
 */
const parseResponseJson = async (res) => {
  try {
    return camelizeKeys(await res.json());
  } catch (err) {
    return;
  }
};

/**
 * @param {String} name Meta Tag name attribute
 * @returns {String} Meta Tag content attribute
 */
const getMeta = (name) =>
  (document.querySelector(`meta[name="${name}"]`) || {}).content?.replace(
    /[=\n]/g,
    ''
  );

/**
 * API Fetch Request Headers
 */
export const apiRequestHeaders = () => ({
  Accept: 'application/vnd.api+json',
  'Accept-Language': 'en-US',
  'Content-Type': 'application/json',
  'X-CSRF-Token': getMeta('csrf-token'),
  Authorization: `Bearer ${getMeta('bravely-token')}`,
  Cookie: document.cookie,
});

/**
 * JSON API Request Headers
 */
export const jsonApiRequestHeaders = () => ({
  Accept: 'application/vnd.api+json',
  'Accept-Language': 'en-US',
  'Content-Type': 'application/vnd.api+json',
  'X-CSRF-Token': getMeta('csrf-token'),
  Authorization: `Bearer ${getMeta('bravely-token')}`,
  Cookie: document.cookie,
});
