import { useState } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash.get';
import parseInt from 'lodash.parseint';
import BravelyData from '../data';

export const INITIAL_PAGINATION = {
  current: 1,
  first: 1,
  next: null,
  last: null,
  prev: null,
};

/**
 * API URL Helper
 * Builds absolute URL with apiHost (subdomain)
 * @param {String} path '/consults'
 * @returns {String}
 * @example '/consults' --> 'https://api.workbravely.com/v1/pro/consults';
 */
export const apiUrl = (path) => `${BravelyData('apiHost', '')}${path}`;

/**
 * IDs Helper
 * @param {Object} response JSON API Response
 * @returns {Array[String]} IDs or ID from record(s) found in response -- { data: [] } | { data: {} }
 */
export const parseResponseIds = ({ response }) =>
  Array.isArray(response.data)
    ? response.data.map((record) => record.id)
    : response.data.id || [];

/**
 * Pagination Helper
 * @param {Object} response JSON API Response
 * @returns {Object} Extra pagination info from response and include current page
 */
export const parseResponsePagination = ({ response }) => {
  const pagination = get(response, 'links.page', INITIAL_PAGINATION);
  return {
    next:
      (pagination.next && parseInt(pagination.next)) || INITIAL_PAGINATION.next,
    prev:
      (pagination.prev && parseInt(pagination.prev)) || INITIAL_PAGINATION.prev,
    first:
      (pagination.first && parseInt(pagination.first)) ||
      INITIAL_PAGINATION.first,
    last:
      (pagination.last && parseInt(pagination.last)) || INITIAL_PAGINATION.last,
  };
};

/**
 * @param {Object} pagination
 * @returns {String|Number}
 */
export const getNextPage = ({
  current,
  pagination: { first, next, last, prev },
}) => (current < next ? next : current);

/**
 * @param {Object} pagination
 * @returns {String|Number}
 */
export const getPreviousPage = ({
  current,
  pagination: { first, next, last, prev },
}) => (prev && current > prev ? prev : current);

/**
 * @param {Object} pagination
 * @returns {Boolean}
 */
export const isLastPage = ({
  current,
  pagination: { first, next, last, prev },
}) => last == current;

/**
 * @param {Object} pagination
 * @returns {Boolean}
 */
export const isFirstPage = ({
  current,
  pagination: { first, next, last, prev },
}) => first == current;

/**
 * @param {Object} pagination
 * @returns {Boolean}
 */
const _hasPrevPage = ({ current, pagination: { first, next, last, prev } }) =>
  prev &&
  !isFirstPage({
    current,
    pagination: { first, next, last, prev },
  });

/**
 * @param {Object} pagination
 * @returns {Boolean}
 */
const _hasNextPage = ({ current, pagination: { first, next, last, prev } }) =>
  last &&
  !isLastPage({
    current,
    pagination: { first, next, last, prev },
  });

/**
 * @param {Object} pagination
 * @returns {Boolean}
 */
export const updatePagination = ({
  current,
  pagination: { first, next, last, prev },
}) => {
  current = 1 * current;
  return {
    next: current < last ? current + 1 : last,
    prev: current > first ? current - 1 : first,
    first,
    last,
  };
};

/**
 * @param {Array} pagedIds
 * @param {Integer} pageNum
 * @param {Object} sourceData (Probably App State)
 * @param {Object} localData (Probably Component State)
 * @returns {Object} localData
 */
export const preparePagedData = ({
  pagedIds,
  pageNum,
  sourceData,
  localData = {},
}) => {
  let pagedData = [];

  pagedIds[pageNum] &&
    pagedIds[pageNum].map((id) => {
      pagedData.push(sourceData[id]);
    });

  return pagedData;
};

/**
 * Custon Pagination Hook for Paged Components
 * @returns {Array}
 */
export function usePagination() {
  const [pagedIds, setPagedIds] = useState({});
  const [isLoading, setIsLoading] = useState(null);
  const [error, setError] = useState(null);
  const [pagination, setPagination] = useState(INITIAL_PAGINATION);

  const hasPrevPage = (pageNum) =>
    _hasPrevPage({ current: pageNum, pagination });

  const hasNextPage = (pageNum) =>
    _hasNextPage({ current: pageNum, pagination });

  return {
    pagedIds,
    setPagedIds,
    isLoading,
    setIsLoading,
    error,
    setError,
    pagination,
    setPagination,
    hasPrevPage,
    hasNextPage,
  };
}

export const paginationPropTypes = {
  pagedIds: PropTypes.object.isRequired,
  setPagedIds: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  setIsLoading: PropTypes.func.isRequired,
  pagination: PropTypes.shape({
    first: PropTypes.any,
    next: PropTypes.any,
    last: PropTypes.any,
    prev: PropTypes.any,
  }),
  setPagination: PropTypes.func.isRequired,
  hasPrevPage: PropTypes.func.isRequired,
  hasNextPage: PropTypes.func.isRequired,
};

export const getAPIHeaders = () => {
  const bearerToken =
    document.querySelector('meta[name="bravely-token"]') &&
    document.querySelector('meta[name="bravely-token"]').content;
  const csrfToken =
    document.querySelector('meta[name="csrf-token"]') &&
    document.querySelector('meta[name="csrf-token"]').content;
  const apiHeaders = {
    Accept: 'application/vnd.api+json',
    'Accept-Language': 'en-US',
    'Content-Type': 'application/vnd.api+json',
    'X-CSRF-Token': csrfToken,
    Authorization: 'Bearer ' + bearerToken,
    Cookie: document.cookie,
  };

  return apiHeaders;
};

export const fetchOptions = (method) => ({
  method: method.toUpperCase(),
  headers: getAPIHeaders(),
  credentials: 'same-origin',
});
