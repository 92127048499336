import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import BravelyData from 'utils/data';
import { en, sp } from './translations';

const { language } = BravelyData('currentUser') || {};

i18n
  // pass the i18n instance to react-i18next
  .use(initReactI18next)
  // initialize i18next. For all options read: https://www.i18next.com/overview/configuration-options
  .init({
    resources: {
      en: {
        translation: en,
      },
      sp: {
        translation: sp,
      },
    },
    lng: language,
    fallbackLng: 'en',
    // Prevent usage of react suspense to avoid breaking the code
    react: { useSuspense: false },
  });

export default i18n;
