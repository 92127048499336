import { camelize } from 'humps';

/**
 * @param {string} key camelCase|snake_case read from PAGE_DATA
 * @param {mixed} fallback null|mixed if key is missing or falsey,
 */
export default function BravelyData(key, fallback = null) {
  const pageDataKey = camelize(key);

  return typeof Bravely.PAGE_DATA === 'object' &&
    Bravely.PAGE_DATA.hasOwnProperty(pageDataKey)
    ? Bravely.PAGE_DATA[pageDataKey]
    : fallback;
}
